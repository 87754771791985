import { Icon, Label, Paragraph, Tooltip } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { FC } from 'react';

export const NO_LIMIT_CABIN_WEIGHT = 'NO_LIMIT';

type CabinBagTooltipProps = {
  cabinBagDimensions: string;
  cabinBagWeight: string;
  alwaysOpened?: boolean;
};

export const CabinBagTooltip: FC<CabinBagTooltipProps> = ({
  cabinBagWeight,
  cabinBagDimensions,
  alwaysOpened = false,
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      sx={{ display: 'inline-flex' }}
      alwaysOpened={alwaysOpened}
      content={
        <div sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
          <Paragraph
            variant="small"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 'xs',
            }}
          >
            <Label variant="medium">{t('checkout.cabinBagSize')}</Label>
            <Label variant="medium">{cabinBagDimensions}</Label>
          </Paragraph>

          {!!cabinBagWeight && cabinBagWeight !== NO_LIMIT_CABIN_WEIGHT && (
            <Paragraph
              variant="small"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Label variant="medium">{t('checkout.cabinBagWeight')}</Label>
              <Label variant="medium">{cabinBagWeight}</Label>
            </Paragraph>
          )}
        </div>
      }
    >
      <Icon
        size="16"
        name="Markers/Tooltip"
      />
    </Tooltip>
  );
};
